import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../modules/auth/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class HilaryErrorInterceptor {
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((response: any) => {
                if (
                    response instanceof HttpErrorResponse &&
                    (response.status === 401)
                ) {
                    this.authService.logout();
                    this.router.navigateByUrl('/not-found');
                }

                if (
                    response instanceof HttpErrorResponse &&
                    response.status === 404
                ) {
                    this.router.navigateByUrl('/not-found');
                }

                return throwError(response);
            })
        );
    }
}
