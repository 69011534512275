import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../constants/constants';
import { HilaryLocalStorageService } from '../services/hilary-local-storage.service';
@Injectable({
    providedIn: 'root'
})
export class HilaryHttpInterceptor {

    constructor(private localStr: HilaryLocalStorageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = {};
        const userInLocalStorage: any = this.localStr.getItem(Constants.AUTH_KEY);
        const userJsonParser: any = JSON.parse(userInLocalStorage);
        if (userJsonParser && userJsonParser.isAuthenicated && userJsonParser.token) {
            headers = Object.assign(headers, { Authorization: `Bearer ${userJsonParser.token}` });
        }
        request = request.clone({
            setHeaders: headers
        });
        return next.handle(request);
    }
}
