import { Component, OnInit, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { GridModel } from './models/grid.model';

@Component({
  selector: 'hilary-grid-control',
  templateUrl: './hilary-grid-control.component.html',
  styleUrls: ['./hilary-grid-control.component.scss']
})
export class HilaryGridControlComponent implements OnInit {
  @Input('grid') gridModel: GridModel<any> = new GridModel({
    primaryColumn: 'id',
    data: [],
    commandColumns: [],
    columns: [],
    title: ''
  });
  constructor() { }

  ngOnInit() {
  }

  public trackColumnByFn(index: number, item: any) {
    return item.name;
  }

  public trackRowByFn(index: number, item: any) {
    return this.gridModel && this.gridModel.primaryColumn && item[this.gridModel.primaryColumn] ? item[this.gridModel.primaryColumn] : index;
  }
}
