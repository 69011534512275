<div class="sidebar-wrapper">
    <div class="logo" routerLink="/dashboard">
        <a class="simple-text logo-mini">
            <div class="logo-img"><img alt="Logo" src="assets/images/logo.png"></div>
        </a>
        <a class="simple-text logo-normal"> Papazi VN </a>
    </div>
    <ul class="nav">
        <!---->
        <li routerlinkactive="active" class="">
            <!---->
            <a [routerLink]="'/'">
                <i class="tim-icons icon-chart-pie-36"></i>
                <p>Dashboard</p>
            </a>
            <!----><!---->
        </li>
        <li class="">
            <a [routerLink]="'/product-category'">
                <i class="tim-icons icon-chart-pie-36"></i>
                <p>Product Category</p>
            </a>
        </li>
        <li class="">
            <a [routerLink]="'/post-category'">
                <i class="tim-icons icon-chart-pie-36"></i>
                <p>Post Category</p>
            </a>
        </li>
        <li class="">
            <a [routerLink]="'/information'">
                <i class="tim-icons icon-puzzle-10 "></i>
                <p>Information</p>
            </a>
        </li>
        <li class="">
            <a [routerLink]="'/privacy-policy'">
                <i class="tim-icons icon-puzzle-10 "></i>
                <p>Privacy Policy</p>
            </a>
        </li>
        <li >
            <a data-toggle="collapse">
                <i class="tim-icons icon-image-02"></i>
                <p>Product<b class="caret"></b></p>
            </a>
            <div class="" id="pages">
                <ul class="nav">
                    <li [routerLink]="'/product'">
                        <a><span class="sidebar-mini-icon">P</span><span class="sidebar-normal">Producst</span></a >
                    </li>
                    <li [routerLink]="'/product/filter'">
                        <a><span class="sidebar-mini-icon">RS</span><span class="sidebar-normal">Product Filters</span></a>
                    </li>
                    <li [routerLink]="'/sale'">
                        <a><span class="sidebar-mini-icon">P</span><span class="sidebar-normal">Sale</span></a >
                    </li>
                </ul>
            </div>
        </li>
        <li >
            <a [routerLink]="'/post'">
                <i class="tim-icons icon-image-02"></i>
                <p>Post</p>
            </a>
        </li>
        <li>
            <a [routerLink]="'/order'">
                <i class="tim-icons icon-basket-simple"></i>
                <p>Order</p>
            </a>
        </li>
        <li>
            <a [routerLink]="'/customer'">
                <i class="tim-icons icon-single-02 "></i>
                <p>Customer</p>
            </a>
        </li>
        
        <li>
            <a [routerLink]="'/user'">
                <i class="tim-icons icon-single-02 "></i>
                <p>User</p>
            </a>
        </li>
    </ul>
</div>
