import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingStateSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loadingState: Observable<boolean> = this.loadingStateSource.asObservable();

  public setLoadingState(newState: boolean): void {
    if (newState === !this.loadingStateSource.value) {
      this.loadingStateSource.next(newState);
    }
  }
}