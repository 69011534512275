<div class="wrapper">
	<div class=" navbar-minimize-fixed d-inline" style="opacity: 0;"><button aria-label="Button" class=" minimize-sidebar btn btn-link btn-just-icon" placement="right"><i class=" tim-icons icon-align-center visible-on-sidebar-regular"></i><i class=" tim-icons icon-bullet-list-67 visible-on-sidebar-mini"></i></button></div>
	<div class="sidebar">
		<app-layout-sidebar></app-layout-sidebar>
	</div>
	<div class="main-panel">
		<app-layout-header></app-layout-header>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
        <footer class=" footer">
            <div class=" container-fluid">
                <ul class=" nav">
                    <li class=" nav-item"><a class=" nav-link" href="https://www.creative-tim.com?ref=bdpa-footer"> Creative Tim </a></li>
                    <li class=" nav-item"><a class=" nav-link" href="https://www.creative-tim.com/about-us?ref=bdpa-footer"> About Us </a></li>
                    <li class=" nav-item"><a class=" nav-link" href="http://blog.creative-tim.com?ref=bdpa-footer"> Blog </a></li>
                </ul>
                <div class=" copyright"> © 2019 made with <i class=" tim-icons icon-heart-2"></i> by Hilary Co. </div>
            </div>
        </footer>
    </div>
    <div class="loading" [hidden]="!(loadingState | async)">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
    <div class="overlay" [hidden]="!(loadingState | async)"></div>
</div>