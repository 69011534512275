import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { paginationConstant } from '@core/constants/pagination';

export interface EventPagingEmit {
    currentPage: number;
    propsSlug: Object;
}

@Component({
    selector: 'hilary-pagination',
    templateUrl: './hilary-pagination.component.html',
    styleUrls: ['./hilary-pagination.component.scss']
})
export class HilaryPaginationComponent implements OnInit, OnChanges {
    listColumsInputData: Array<any>;
    countItemPerPage: number = paginationConstant.searchNumberItem;
    listPaginations: Array<number> = [];
    currentPage: number = 1;
    propsSlug: Object = {};
    @Input() total: number = 1;
    @Output('eventSearching') eventSearching: EventEmitter<any> = new EventEmitter<any>(true);
    @Output('eventPaging') eventPaging: EventEmitter<EventPagingEmit> = new EventEmitter<EventPagingEmit>(true);

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.total) {
            const lengthOfData: number = this.total ? Math.ceil(this.total / this.countItemPerPage) : 0;
            this.listPaginations = Array(lengthOfData).fill(null).map((x: any, i: number) => i);
        }
    }

    handlePaging(isNumberPaging: boolean, typeOrNumberPaging: string | number): void {
        if (isNumberPaging) {
            this.currentPage = +typeOrNumberPaging;
        } else {
            typeOrNumberPaging === 'next'
                ? (this.currentPage === this.listPaginations.length ? this.currentPage = this.listPaginations.length : this.currentPage += 1)  
                : (this.currentPage === 1 ? this.currentPage = 1 : this.currentPage -= 1);
        }
        this.propsSlug['limit'] = this.currentPage * this.countItemPerPage;
        this.eventPaging.emit({ currentPage: this.currentPage, propsSlug: this.propsSlug });
    }
}
