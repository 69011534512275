import { Component, OnInit, forwardRef, AfterViewInit, OnDestroy } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-hilary-ckeditor',
    templateUrl: './hilary-ckeditor.component.html',
    styleUrls: ['./hilary-ckeditor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => HilaryCkeditorComponent),
            multi: true
        }
    ]
})
export class HilaryCkeditorComponent implements OnInit, ControlValueAccessor, OnDestroy {
    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '300px',
        minHeight: '300px',
        sanitize: true,
    };
    formEditorContent: FormGroup;
    onChange: (...agrs) => void;
    onTouch: (...agrs) => void;

    private editorContentChangeSubscription: Subscription;

    constructor(
        private fb: FormBuilder,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit() {
        this.buildFormEditorContent();
    }

    private buildFormEditorContent(): void {
        this.formEditorContent = this.fb.group({
            contentEditor: [null]
        });

        this.editorContentChangeSubscription = this.formEditorContent.get('contentEditor').valueChanges.pipe(
            debounceTime(500)
        ).subscribe(data => {
            this.onChange(data);
        })
    }

    public onValueContentEditorChange(event): void {
        this.writeValue(event);
    }

    writeValue(value: string): void {
        if (value && this.formEditorContent) {
            this.formEditorContent.get('contentEditor').setValue(value);
            if (this.onChange) {
                this.onChange(value);
            }
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    ngOnDestroy() {
        if (this.editorContentChangeSubscription) {
            this.editorContentChangeSubscription.unsubscribe();
        }
    }

    fileChoosen(event) {
        this.formEditorContent.get('contentEditor').setValue(`${ this.formEditorContent.get('contentEditor').value } <img src="${ event.path }" />`);
    }
}
