	<div class="container">
    <br>
    <div class="row">
        <div class="col-md-12 ">
            <div class="error-text">
                <h1 class="error">404 Not Found</h1>
                <div class="im-sheep">
                    <div class="top">
                        <div class="body"></div>
                        <div class="head">
                            <div class="im-eye one"></div>
                            <div class="im-eye two"></div>
                            <div class="im-ear one"></div>
                            <div class="im-ear two"></div>
                        </div>
                    </div>
                    <div class="im-legs">
                        <div class="im-leg"></div>
                        <div class="im-leg"></div>
                        <div class="im-leg"></div>
                        <div class="im-leg"></div>
                    </div>
                </div>
                <h4>Oops! This page Could Not Be Found!</h4>
                <br>
                <ul class="nav nav-pills justify-center">
                  <li class="nav-item active">
                    <a class="nav-link active" routerLink="/dashboard">
                      <span></span><i class="tim-icons icon-spaceship"></i> Dashboard
                    </a>
                  </li>
                  <li class="nav-item active" routerLink="/product">
                    <a class="nav-link active">
                      <span></span><i class="tim-icons icon-settings-gear-63"></i> Products
                    </a>
                  </li>
                </ul>
            </div>
        </div>
    </div>
</div>