<div class="media-box">
    <button class="btn media-button" type="button" (click)="openMediaBox(content)">Add Media</button>
</div>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">List Media</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row content-box-gallery" (scroll)="onScroll($event)">
            <div class="col-3 folder-box">
                <div class="folder-title">
                    <h4 class="title">Folders</h4>
                    <button class=" btn btn-simple" (click)="createNewFolder()"><i class="tim-icons icon-simple-add"></i> New Folder </button>
                </div>
                <div class="list-folder">
                    <div class="folder-engine" (dblclick)="openFolder(0)">
                        <span class="icon"><i class="tim-icons icon-book-bookmark"></i></span>
                        <span class="folder-name">All files</span>
                    </div>
                    <div class="folder-engine" *ngFor="let item of listFolders;" (dblclick)="openFolder(item.id)">
                        <span class="icon"><i class="tim-icons icon-book-bookmark"></i></span>
                        <span class="folder-name">{{ item?.name }}</span>
                    </div>
                    <form [formGroup]="formAddFolder" *ngIf="statusPage.isCreatingFolder">
                        <div class="folder-engine">
                            <span class="icon"><i class="tim-icons icon-book-bookmark"></i></span>
                            <span class="folder-name"><input type="text" class="form-control" formControlName="name" placeholder="Type name folder here"></span>
                            <span class="count-total" (click)="submitAddFolder()">Save</span>
                        </div>
                        <app-show-error [control]="formAddFolder.controls['name']" [submitted]="statusPage.submittedAddFolder"></app-show-error>
                    </form>
                </div>
            </div>
            <div class="col-9 media-list">
                <div class="folder-title">
                    <h4 class="title">Media Library</h4>
                    <button class=" btn btn-simple" (click)="changeTabUploadImage()"><i class="tim-icons icon-simple-add"></i> Add New </button>
                </div>
                <div class="content-image">
                    <div class="list-images" *ngIf="!statusPage.isUploadingImage; else addMedia">
                        <div class="image-item" *ngFor="let item of listImages; let i = index;">
                            <div
                                [ngClass]="{ 'active': listImageActive[item.id] }"
                                [ngStyle]="{
                                    'background-image': 'url(' + item.path + ')',
                                    'background-size': 'cover',
                                    'background-position': 'center center',
                                    'width': '120px',
                                    'height': '120px'
                                }"
                                (dblclick)="chooseImage(item)"
                                (click)="chooseImageMultiple(item, i)"
                            >
                            </div>
                        </div>
                    </div>
                    <ng-template #addMedia>
                        <div class="add-media">
                            <div class="media-content">
                                <app-upload
                                    [maxWidth]="150"
                                    [maxHeight]="150"
                                    (fileUploaded)="fileUploaded($event)"
                                    [multi]="true"
                                    [removeAfterUpload]="true"
                                    [currentFolder]="currentFolder"
                                >
                                </app-upload>
                                <button class="btn btn-default" (click)="removeUploadPage()">Cancel</button>
                            </div>
                        </div>
                    </ng-template>
                </div>
                <div class="loading-section" *ngIf="statusPage.loadingListImage">
                    <img src="/assets/images/loading.gif" alt="Loading">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" *ngIf="multiChoose && listImageChoosen && listImageChoosen.length > 0" (click)="emitListImage()">Choose</button>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
