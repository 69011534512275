<div class="label-upload-file">
    <label class="default-upload {{ classStyle }}" *ngIf="arrayFileUpload.length < 1;else localImage">
        <img alt="Image" [src]="defaultImage ? defaultImage : '/assets/images/default-image.png'" class="default-image"  [ngStyle]="{'max-width.px': maxWidth, 'max-height.px': maxHeight}">
        <input type="file" hidden (change)="selectFile($event)" [multiple]="multi">
    </label>
    <span class="deleteUploaded" (click)="deleteImageUpload()" *ngIf="defaultImage">
        <img src="/assets/images/delete-image-uploaded.png" alt="Delete">
    </span>
</div>
<ng-template #localImage>
    <div class="image-col {{ classStyle }}" *ngFor="let img of arrayFileUpload; let i = index">
        <label for="fileImage">
            <img *ngIf="img.url != null" [src]="img.url" [ngStyle]="{'max-width.px': maxWidth, 'max-height.px': maxHeight}" alt="Image" id="fileImage">
        </label>
        <span class="deleteUploaded" (click)="deleteImageUpload(i)">
            <img alt="Image" src="/assets/images/delete-image-uploaded.png" alt="Delete">
        </span>
        <span class="big-size-image btn-danger" *ngIf="img.bigSize">
            Hình ảnh này dung lượng quá lớn
        </span>
        <span class="overlay" *ngIf="img.bigSize"></span>
    </div>
</ng-template>
