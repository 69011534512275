import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreRoutingModule } from './core-routing.module';
import { LayoutHeaderComponent } from './layouts/layout-header/layout-header.component';
import { LayoutFooterComponent } from './layouts/layout-footer/layout-footer.component';
import { LayoutSidebarComponent } from './layouts/layout-sidebar/layout-sidebar.component';
import { PublicPageComponent } from './pages/public-page/public-page.component';
import { PrivatePageComponent } from './pages/private-page/private-page.component';
import { SharedModule } from '../shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HilaryHttpInterceptor } from './interceptors/http.interceptor';
import { HilaryErrorInterceptor } from './interceptors/error.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { NotFoundComponent } from './pages/not-found/not-found.component';

@NgModule({
    declarations: [LayoutHeaderComponent, LayoutFooterComponent, LayoutSidebarComponent, PublicPageComponent, PrivatePageComponent, NotFoundComponent],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        CoreRoutingModule,
        SharedModule,
        ToastrModule.forRoot()
    ],
    providers: [
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: HilaryHttpInterceptor,
        //     multi: true
        // },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: HilaryErrorInterceptor,
        //     multi: true
        // }
    ]
})
export class CoreModule { }
