import { Injectable } from '@angular/core';
import { ApiBaseService } from 'src/app/core/services/api-base.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from 'src/app/core/constants/constants';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { HilaryLocalStorageService } from 'src/app/core/services/hilary-local-storage.service';
import { HttpResponseApiModel } from '@core/models/http-response.api-model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ApiBaseService {

  constructor(protected http: HttpClient, private localStr: HilaryLocalStorageService) {
    super(http);
  }

  isAuthencated(): boolean {
    const dataUserInLocalStorage: any = this.localStr.getItem(Constants.AUTH_KEY);
    if (dataUserInLocalStorage) {
      const dataUser: any = JSON.parse(dataUserInLocalStorage);
      if (dataUser && dataUser.isAuthenicated && dataUser.user) {
        const dateTimeNow = moment().utcOffset(0).format('X');
        const dateUser = moment.unix(dataUser.user.expires_at).utcOffset(0).format('X');
        if (dateTimeNow > dateUser) {
          return false;
        }
        return true;
      }
      return false;
    }

    return false
  }

  login<T, C>(data: C): Observable<T> {
    return super.post<HttpResponseApiModel<T>, C>('auth/login/', data).pipe(
      map((response: HttpResponseApiModel<T>) => {
        
        return response.data;
      })
    );
  }

  saveInfoToLocalStorage(data: any) {
    const expriesTime = moment().utcOffset(0).add(720, 'minutes').format('X');
    const dataToSave: any = { token: data.token, user: { ...data.user, expires_at: expriesTime }, isAuthenicated: true };
    this.localStr.setItem(Constants.AUTH_KEY, JSON.stringify(dataToSave));
  }

  logout(): void {
    this.localStr.removeItem(Constants.AUTH_KEY);
  }
}