<div class="row">
    <div class="col-sm-5">
        <div class="dataTables_info" id="example1_info" role="status" *ngIf="total > 0">
            Showing {{ ((currentPage - 1) * countItemPerPage) + 1 }} to 
            {{ (currentPage * countItemPerPage) > total ? total : (currentPage * countItemPerPage) }} of
            {{ total || 0 }} entries</div>
    </div>
    <div class="col-sm-7" *ngIf="listPaginations && listPaginations.length > 1">
        <div class="dataTables_paginate paging_simple_numbers" id="ali-pagination_paginate">
            <ul class="pagination">
                <li
                    class="paginate_button previous"
                    id="example1_previous"
                    (click)="handlePaging(false, 'prev')"
                    [ngClass]="{ 'disabled' : currentPage === 1 }"
                    [attr.disabled]="currentPage === 1"
                >
                    <a href="javascript:void(0);" data-dt-idx="0" tabindex="0">
                        Previous
                    </a>
                </li>
                <li
                    class="paginate_button"
                    *ngFor="let paginate of listPaginations;"
                    [ngClass]="{ 'active' : currentPage === (paginate + 1) }"
                >
                    <a
                        href="javascript:void(0);"
                        [attr.tabindex]="0"
                        (click)="handlePaging(true, paginate + 1)"
                    >
                        {{ paginate + 1 }}
                    </a>
                </li>
                <li
                    class="paginate_button next"
                    id="example1_next"
                    (click)="handlePaging(false, 'next')"
                    [ngClass]="{ 'disabled' : currentPage === listPaginations.length }"
                >
                    <a href="javascript:void(0);">
                        Next
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>