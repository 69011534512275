import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-image-preview',
    templateUrl: './image-preview.component.html',
    styleUrls: ['./image-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagePreviewComponent implements OnInit {
    @Input('url') url: string;
    @Input('maxWidth') maxWidth: number;
    @Input('maxHeight') maxHeight: number;
    @Input('allowDelete') allowDelete: boolean = false;
    @Output('eventDelete') eventDeleteEmitter: EventEmitter<any> = new EventEmitter<boolean>();

    constructor() { }

    ngOnInit() {
    }

    deleteImageUpload(): void {
        if (this.allowDelete) {
            this.eventDeleteEmitter.emit(true);
        }
    }
}
