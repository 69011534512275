import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ShowErrorComponent } from './components/show-error/show-error.component';
import { HilaryGridControlComponent } from './components/hilary-grid-control/hilary-grid-control.component';
import { UploadComponent } from './components/upload/upload.component';
import { HilaryPaginationComponent } from './components/hilary-pagination/hilary-pagination.component';
import { HilaryCkeditorComponent } from './components/hilary-ckeditor/hilary-ckeditor.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { HilaryCkfinderComponent } from './components/hilary-ckfinder/hilary-ckfinder.component';
import { SanitizeHtmlPipe } from './pipes/sanitize.pipe';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
@NgModule({
    declarations: [
        ShowErrorComponent,
        HilaryGridControlComponent,
        UploadComponent,
        HilaryPaginationComponent,
        HilaryCkeditorComponent,
        HilaryCkfinderComponent,
        SanitizeHtmlPipe,
        ImagePreviewComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        AngularEditorModule,
        NgbModalModule
    ],
    exports: [
        RouterModule,
        ReactiveFormsModule,
        ShowErrorComponent,
        HilaryGridControlComponent,
        UploadComponent,
        HilaryPaginationComponent,
        AngularEditorModule,
        HilaryCkeditorComponent,
        HilaryCkfinderComponent,
        SanitizeHtmlPipe,
        ImagePreviewComponent
    ]
})
export class SharedModule { }
