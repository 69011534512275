import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { isPlatformBrowser } from '@angular/common';


@Injectable({
    providedIn: 'root'
})
export class HilaryLocalStorageService {

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    }

    getItem(key: string): string | null {
        let result = null;
        if (isPlatformBrowser(this.platformId)) {
            result = localStorage.getItem(key);
        }
        return result;
    }

    setItem(key: string, value: any) {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(key, value);
        }
    }

    removeItem(key: string): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem(key);
        }
    }
}
