import {Injectable} from '@angular/core';
import {ApiBaseService} from '@core/services/api-base.service';
import {HttpClient} from '@angular/common/http';
import {CreateMediaFolderApiModel} from '@shared/models/media-folder/create-media-folder.api-model';
import {Observable} from 'rxjs';
import {paginationConstant} from '@core/constants/pagination';
import {MediaFolderEntity, FolderEntity} from '@shared/models/media-folder/media-folder.entity-model';
import {HttpResponseApiModel} from '@core/models/http-response.api-model';

@Injectable({
    providedIn: 'root'
})
export class MediaService extends ApiBaseService {
    constructor(protected http: HttpClient) {
        super(http);
    }

    public createNewFolder(folderData: CreateMediaFolderApiModel): Observable<HttpResponseApiModel<FolderEntity>> {
        const url: string = `media/folder`;
        return super.post(url, folderData);
    }

    public getListMedia(
        parentId: number,
        page: number,
        limit: number = paginationConstant.searchNumberItem,
        offset?: number
    ): Observable<HttpResponseApiModel<MediaFolderEntity>> {
        const queryParams: { [key: string]: string | number } = {
            parent_id: parentId,
            page,
            limit
        };
        if (offset && offset > 0) {
            queryParams.offset = offset;
        }
        const url: string = super.revertSlugToUrl(`media/folder`, queryParams);
        return super.get(url);
    }
}
