<div class=" col-md-12">
    <div class=" card">
        <div class=" card-header">
            <div class=" tools float-right">
                <div class="dropdown" dropdown="">
                    <!-- <button aria-label="Button" class=" btn btn-default dropdown-toggle btn-link btn-icon dropdown-toggle"
                        data-toggle="dropdown" dropdowntoggle="" type="button"><i
                            class=" tim-icons icon-settings-gear-63"></i></button> -->
                </div>
            </div>
            <h4 class=" card-title">{{ gridModel?.title }}</h4>
        </div>
        <div class=" card-body">
            <div class=" table-responsive">
                <table class=" table">
                    <thead class=" text-primary">
                        <tr>
                            <ng-container
                                *ngFor="let column of gridModel.columns; index as i; trackBy: trackColumnByFn">
                                <ng-template [ngIf]="column.visible">
                                    <th [ngStyle]="column.css" [class]="column.class">
                                        {{ column.displayName }}
                                    </th>
                                </ng-template>
                            </ng-container>
                            <ng-container *ngIf="gridModel.commandColumns.length > 0">
                                <th [innerHTML]="'Actions'" [attr.colspan]="gridModel.commandColumns.length"
                                    class="text-right">
                                </th>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of gridModel.data; index as i; trackBy: trackRowByFn">
                            <ng-container
                                *ngFor="let column of gridModel.columns; index as i; trackBy: trackColumnByFn">
                                <ng-template [ngIf]="column.visible">
                                    <td [ngStyle]="column.css" [ngClass]="{ 'can-hover': column.canHover }"
                                        [class]="column.class" (click)="column.onClick && column.onClick(item)"
                                        [innerHTML]="item[column.name] ? (column.type == 'function' ? item[column.name]() : item[column.name]) : column.defaultValue">
                                    </td>
                                </ng-template>
                            </ng-container>
                            <ng-container *ngIf="gridModel.commandColumns.length  > 0">
                                <td class="text-right">
                                    <button aria-label="Button" class="btn btn-link btn-sm btn-simple" type="button"
                                        (click)="command.onClick && command.onClick(item, i)"
                                        *ngFor="let command of gridModel.commandColumns" [ngClass]="command.class"
                                        [innerHTML]="command.text">
                                    </button>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>