import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-private-page',
  templateUrl: './private-page.component.html',
  styleUrls: ['./private-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrivatePageComponent implements OnInit {
  loadingState: Observable<boolean> = this.loadingService.loadingState;
  constructor(
    private loadingService: LoadingService
  ) { }
  ngOnInit() {
  }
}
