import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivatePageComponent } from './pages/private-page/private-page.component';
import { PublicPageComponent } from './pages/public-page/public-page.component';
import { AuthGuardService } from './guards/auth.guard';
import { AuthNoGuardService } from './guards/auth-no-guard.service';
import { NotFoundComponent } from './pages/not-found/not-found.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: PrivatePageComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./../modules/home/home.module').then(m => m.HomeModule)
      }
    ],
    canActivate: [AuthGuardService]
  },
  {
    path: 'store',
    component: PrivatePageComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./../modules/store/store.module').then(m => m.StoreModule)
      }
    ],
    canActivate: [AuthGuardService]
  },
  {
    path: 'auth',
    component: PublicPageComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./../modules/auth/auth.module').then(m => m.AuthModule)
      }
    ],
    canActivate: [AuthNoGuardService]
  },
  {
    path: 'product-category',
    component: PrivatePageComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./../modules/product-category/product-category.module').then(m => m.ProductCategoryModule)
      }
    ]
  },
  {
    path: 'post-category',
    component: PrivatePageComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./../modules/post-category/post-category.module').then(m => m.PostCategoryModule)
      }
    ]
  },
  {
    path: 'product',
    component: PrivatePageComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./../modules/product/product.module').then(m => m.ProductModule)
      }
    ]
  },
  {
    path: 'post',
    component: PrivatePageComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./../modules/post/post.module').then(m => m.PostModule)
      }
    ]
  },
  {
    path: 'sale',
    component: PrivatePageComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./../modules/sale/sale.module').then(m => m.SaleModule)
      }
    ]
  },
  {
    path: 'information',
    component: PrivatePageComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./../modules/information/information.module').then(m => m.InformationModule)
      }
    ]
  },
  {
    path: 'customer',
    component: PrivatePageComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./../modules/customer/customer.module').then(m => m.CustomerModule)
      }
    ]
  },
  {
    path: 'privacy-policy',
    component: PrivatePageComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./../modules/policy-privacy/policy-privacy.module').then(m => m.PolicyPrivacyModule)
      }
    ]
  },
  {
    path: 'order',
    component: PrivatePageComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('../modules/order/order.module').then(m => m.OrderModule),
      }
    ]
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
