import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './../../modules/auth/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthNoGuardService implements CanActivate {

    constructor(private authService: AuthService, private route: Router, private router: ActivatedRoute) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isAuthencated() === false) {
          return true;
        }
        this.router.queryParams.subscribe(params => this.route.navigateByUrl(params.return || '/dashboard'));
        return false;
      }
}