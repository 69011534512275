import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AbstractControlDirective, AbstractControl, ValidationErrors } from '@angular/forms';
import { of, Observable } from 'rxjs';

@Component({
  selector: 'app-show-error',
  templateUrl: './show-error.component.html',
  styleUrls: ['./show-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowErrorComponent implements OnChanges {
  @Input() control: AbstractControlDirective | AbstractControl;
  @Input() errors: ValidationErrors | null;
  @Input() submitted: boolean;
  private readonly controlField: any = {
    minlength: 'requiredLength',
  };
  shouldShowError: boolean = false;
  private readonly errorMessages = {
    required: () => of('Bạn phải điền vào trường này'),
    minlength: (length: number) => of(`Tối thiểu phải có ${length} ký tự`),
    maxlength: (length: number) => of(`Tối đa chỉ có ${length} ký tự`),
    email: () => of('Vui lòng nhập đúng định dạng email'),
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes && this.submitted) {
      const isValid = this.control
        && this.errors
        && (this.control.dirty || this.control.touched);
      if (!isValid && this.shouldShowError) {
        this.shouldShowError = false;
      } else {
        this.shouldShowError = true;
      }
      this.cdr.markForCheck();
    }
  }

  shouldShowErrors(): boolean {
    return (
      this.control &&
      this.control.errors &&
      (this.control.dirty || this.control.touched || this.submitted)
    );
  }

  listOfErrors(): Observable<string>[] {
    if (!this.control.errors) { return []; }
    return Object.keys(this.control.errors).map((field) => {
      const nextSement: any = this.controlField[field]
        ? this.control.errors[field][this.controlField[field]]
        : this.control.errors[field];
      return this.getMessage(field, nextSement);
    });
  }

  private getMessage(type: string, params: any) {
    return this.errorMessages[type](params);
  }

  constructor(private readonly cdr: ChangeDetectorRef) { }
}
