import { ColumnModel } from './column.model';
import { CommandColumnModel } from './command-column.model';

export class GridModel<T> {
    public primaryColumn: string = 'id';
    public data: T[];
    public columns: Array<ColumnModel> = [];
    public commandColumns: Array<CommandColumnModel> = [];
    public title: string;

    constructor(init?: Partial<GridModel<T>>) {
        Object.assign(this, init);
    }
}
