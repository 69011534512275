import {Component, OnInit, HostListener, Output, EventEmitter, Input} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {MediaService} from '@shared/services/media.service';
import {MediaFolderEntity, FolderEntity} from '@shared/models/media-folder/media-folder.entity-model';
import {paginationConstant} from '@core/constants/pagination';
import {HttpResponseApiModel} from '@core/models/http-response.api-model';
import { Observable } from 'rxjs';

const pageSize: number = 18;

@Component({
    selector: 'app-hilary-ckfinder',
    templateUrl: './hilary-ckfinder.component.html',
    styleUrls: ['./hilary-ckfinder.component.scss']
})
export class HilaryCkfinderComponent implements OnInit {
    @Input('multi') multiChoose: boolean = false;
    @Output('fileChoosen') fileChoosen: EventEmitter<any> = new EventEmitter<any>();
    formAddFolder: FormGroup;
    currentFolder: number = 0;
    countUploadedMedia: number = 0;
    statusPage: any = {
        isCreatingFolder: false,
        submittedAddFolder: false,
        isUploadingImage: false,
        loadingListImage: false,
        removeLoading: false
    };
    listFolders: Array<FolderEntity> = [];
    listImages: Array<any> = [];
    modalOpenRef: NgbModalRef;
    currentPage: number = 1;
    listImageChoosen: Array<any> = [];
    listImageActive: { [key: number]: boolean } = {};
    private currentOffsetNumber: number = 0;

    constructor(
        private modalService: NgbModal,
        private fb: FormBuilder,
        private mediaService: MediaService
    ) {
    }

    ngOnInit() {
        this.initFormCreateFolder();
    }

    initFormCreateFolder(): void {
        this.formAddFolder = this.fb.group({
            name: [null, Validators.required]
        });
    }

    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            if (!this.statusPage.loadingListImage && !this.statusPage.removeLoading) {
                this.statusPage.loadingListImage = true;
                this.fetchListMedia(this.currentFolder, this.currentPage + 1, pageSize);
            }
        }
    }

    openMediaBox(content: any): void {
        this.modalOpenRef = this.modalService.open(content, {size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-media-box'});
        
        this.modalOpenRef.result.then(() => {
            this.resetData();
        });
        
        this.fetchListMedia(this.currentFolder, this.currentPage);
    }

    createNewFolder(): void {
        this.statusPage.isCreatingFolder = !this.statusPage.isCreatingFolder;
    }

    submitAddFolder(): void {
        this.statusPage.submittedAddFolder = true;
        if (this.formAddFolder.valid) {
            const formValue: any = this.formAddFolder.value;
            this.mediaService.createNewFolder({...formValue, parent_id: this.currentFolder})
                .subscribe((data: HttpResponseApiModel<FolderEntity>) => {
                    this.listFolders.push(data.data);
                    this.formAddFolder.reset();
                    this.statusPage.isCreatingFolder = false;
                });
        }
    }

    fetchListMedia(parentId: number, page: number, limit: number = pageSize): void {
        let query: Observable<any>;
        if (this.currentOffsetNumber > 0) {
            query = this.mediaService.getListMedia(parentId, page, limit, this.currentOffsetNumber);
        } else {
            query = this.mediaService.getListMedia(parentId, page, limit);
        }
        query.subscribe((data: HttpResponseApiModel<MediaFolderEntity>) => {
            this.listFolders = data.data.folders;
            this.currentOffsetNumber = 0;
            if (data.data.images.length > 0) {
                this.listImages = [].concat(this.listImages).concat(data.data.images);
                this.currentPage = page;
            } else {
                this.statusPage.removeLoading = true;
            }
            this.statusPage.loadingListImage = false;
        });
    }

    resetData(): void {
        this.formAddFolder.reset();
        this.statusPage = {
            isCreatingFolder: false,
            submittedAddFolder: false,
            isUploadingImage: false,
            loadingListImage: false,
            removeLoading: false
        };
        this.listFolders = [];
        this.currentFolder = 0;
    }

    openFolder(folderId: number): void {
        this.listFolders = [];
        this.listImages = [];
        this.currentFolder = folderId;
        this.currentPage = 1;
        this.statusPage = {
            isCreatingFolder: false,
            submittedAddFolder: false,
            isUploadingImage: false,
            loadingListImage: false,
            removeLoading: false
        };
        this.fetchListMedia(this.currentFolder, this.currentPage);
    }

    fileUploaded(event: any): void {
        this.listImages.unshift(...event);
        this.removeUploadPage();
        this.countUploadedMedia += event.length;
        this.currentPage = Math.floor(event.length / pageSize) + 1;
        this.currentOffsetNumber = (event.length % pageSize);
    }

    changeTabUploadImage(): void {
        this.statusPage.isUploadingImage = true;
    }

    removeUploadPage(): void {
        this.statusPage.isUploadingImage = false;
    }

    chooseImage(item): void {
        if (!this.multiChoose) {
            this.fileChoosen.emit(item);
        } else {
            this.fileChoosen.emit([item]);
        }
        this.closeModal();
    }

    closeModal(): void {
        this.modalOpenRef.close('No reason');
    }

    public chooseImageMultiple(item: any, index: number): void {
        if (this.multiChoose && item.id) {
            this.listImageActive[item.id] = !this.listImageActive[item.id];
            if (this.listImageActive[item.id]) {
                this.listImageChoosen.push(item);
            } else {
                this.listImageChoosen = this.listImageChoosen.filter((image: any) => image.id !== item.id);
            }
        }
    }

    public emitListImage(): void {
        if (this.multiChoose && this.listImageChoosen && this.listImageChoosen.length > 0) {
            this.fileChoosen.emit(this.listImageChoosen);
            this.closeModal();
        }
    }
}
