import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Constants } from '@core/constants/constants';
import { UploadService } from './upload.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingService } from '@core/services/loading.service';

@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
    @Input() multi: boolean;
    @Input('percentage') percentage: number;
    @Input('defaultImage') defaultImage: string;
    @Input() classStyle: string;
    @Output() fileUploaded = new EventEmitter<any[]>();
    @Input() maxWidth: number = 240;
    @Input() maxHeight: number = 240;
    progress: { percentage: number } = { percentage: -1 };
    arrayFileUpload: any = [];
    url: string = '';
    @Input() removeAfterUpload: boolean = false;
    @Output('fileRemoved') fileRemoved: EventEmitter<any> = new EventEmitter<any>();
    @Input('currentFolder') currentFolder: number = 0;

    constructor(
        private uploadService: UploadService,
        private loadingService: LoadingService
    ) { }
    ngOnInit() {
    }
    selectFile(event) {
        this.progress.percentage = 0;
        const selectedFiles: Array<File> = event.target.files;
        if (selectedFiles && selectedFiles.length > 0) {
            this.loadingService.setLoadingState(true);
            this.uploadLocal(selectedFiles);
            const filesToEmit: Array<File> = this.filterFileSize(selectedFiles);
            this.uploadService.postFile(filesToEmit, this.currentFolder).subscribe(
                (data: any) => {
                    this.fileUploaded.emit(data.data);
                    if (this.removeAfterUpload) {
                        this.arrayFileUpload.length = 0;
                    }
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.setLoadingState(false);
                    alert(false);
                    if (this.multi) {
                        this.arrayFileUpload.length = 0;
                    } else {
                        this.arrayFileUpload[0].url = this.defaultImage;
                    }
                },
                () => {
                    this.loadingService.setLoadingState(false);
                }
            );
        }
    }

    filterFileSize(arrayFile: Array<File>): Array<File> {
        const result: Array<File> = [];
        if (arrayFile && arrayFile.length > 0) {
            for (const file of arrayFile) {
                const fileSize = file.size / 1024 / 1024;
                if (fileSize <= Constants.MAX_FILE_SIZE_UPLOAD) {
                    result.push(file);
                }
            }
        }
        return result;
    }

    private uploadLocal(selectedFiles) {
        if (selectedFiles.length > 0) {
            for (let i = 0; i < selectedFiles.length; i++) {
                const reader: any = new FileReader();
                const file = selectedFiles[i];
                const image: { url: string, filename: string, filetype: string, value: any, bigSize: number } = {
                    url: null,
                    filename: null,
                    filetype: null,
                    value: null,
                    bigSize: 0
                };
                const fileSize = file.size / 1024 / 1024;
                reader.readAsDataURL(selectedFiles[i]);
                reader.onload = event => {
                    image.url = event.target.result;
                };
                image.filename = file.name;
                image.filetype = file.type;
                image.value = reader.result;
                if (fileSize > Constants.MAX_FILE_SIZE_UPLOAD) {
                    image.bigSize = 1;
                }
                if (this.multi) {
                    this.arrayFileUpload.push(image);
                } else {
                    this.arrayFileUpload[i] = image;
                }
            }
        }
    }

    deleteImageUpload(index?: number) {
        if (typeof index === 'number' && index >= 0) {
            this.progress.percentage = -1;
            this.arrayFileUpload.splice(index, 1);
        } else {
            this.defaultImage = '/assets/images/default-image.png';
        }
        this.fileRemoved.emit(true);
    }
}
