import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiBaseService } from 'src/app/core/services/api-base.service';

@Injectable({
    providedIn: 'root'
})
export class UploadService extends ApiBaseService {
    constructor(protected http: HttpClient) {
        super(http)
    }

    postFile(files: Array<File>, folderId: number): Observable<any> {
        const formData: FormData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i], files[i].name);
            formData.append('folderId', folderId.toString())
        }
        return super.post(`media/upload/image/`, formData);
    }
}
