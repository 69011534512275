<nav class="navbar navbar-expand-lg navbar-absolute navbar-transparent">
    <div class=" container-fluid">
        <div class=" navbar-wrapper">
            <div class=" navbar-minimize d-inline"><button aria-label="Button" class=" minimize-sidebar btn btn-link btn-just-icon" placement="right" tooltil="Sidebar toggle"><i class=" tim-icons icon-align-center visible-on-sidebar-regular"></i><i class=" tim-icons icon-bullet-list-67 visible-on-sidebar-mini"></i></button></div>
            <div class=" navbar-toggle d-inline"><button aria-label="Button" class="navbar-toggler" type="button"><span class=" navbar-toggler-bar bar1"></span><span class=" navbar-toggler-bar bar2"></span><span class=" navbar-toggler-bar bar3"></span></button></div>
            <a class=" navbar-brand" href="javascript:void(0)"> Dashboard </a>
        </div>
        <button aria-label="Button" class=" navbar-toggler" id="navigation" type="button"><span class=" navbar-toggler-bar navbar-kebab"></span><span class=" navbar-toggler-bar navbar-kebab"></span><span class=" navbar-toggler-bar navbar-kebab"></span></button>
        <div class="navbar-collapse collapse" id="navigation" style="display: none;">
            <ul class=" navbar-nav ml-auto">
                <li class="dropdown nav-item" (click)="handleLogout()">
                    <a class=" dropdown-toggle nav-link dropdown-toggle" data-toggle="dropdown" dropdowntoggle="" href="javascript:void(0)">
                        <i class=" tim-icons icon-wallet-43"></i>
                        &nbsp;Logout
                    </a>
                </li>
                <li class="dropdown nav-item" dropdown="" placement="bottom left">
                    <a class="nav-link dropdown-toggle" data-toggle="dropdown" dropdowntoggle="" href="#">
                        <div class=" photo"><img alt="Profile Photo" src="assets/images/mike.webp"></div>
                        <b class=" caret d-none d-lg-block d-xl-block"></b>
                        <p class=" d-lg-none">Log out</p>
                    </a>
                    <!---->
                </li>
                <li class=" separator d-lg-none"></li>
            </ul>
        </div>
    </div>
</nav>
<div class=" modal modal-search fade" id="searchModal" role="dialog" tabindex="0">
    <div class=" modal-dialog" role="document">
        <div class=" modal-content">
            <div class=" modal-header"><input class=" form-control" id="inlineFormInputGroup" placeholder="SEARCH" type="text"><button aria-label="Button" class=" close" data-dismiss="modal" type="button"><i class=" tim-icons icon-simple-remove"></i></button></div>
        </div>
    </div>
</div>