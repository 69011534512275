<div class="preview-image" *ngIf="url; else noImage;" [ngStyle]="{'max-width.px': maxWidth, 'max-height.px': maxHeight}">
    <span class="deleteUploaded" (click)="deleteImageUpload()" *ngIf="allowDelete">
        <img src="/assets/images/delete-image-uploaded.png" alt="Delete">
    </span>
    <img [src]="url" alt="Image">
</div>

<ng-template #noImage>
    <div class="preview-image" [ngStyle]="{'max-width.px': maxWidth, 'max-height.px': maxHeight}">
        <img alt="Image" src="/assets/images/default-image.png" class="default-image">
    </div>
</ng-template>